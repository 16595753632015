import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { StyleReset } from "./StyleReset";

const Calendar = React.lazy(() => import("./Calendar/Calendar"));
const Groups = React.lazy(() => import("./Groups/Groups"));
const EventsList = React.lazy(() => import("./EventsList/EventsList"));
const UpcomingServices = React.lazy(() =>
	import("./UpcomingServices/UpcomingServices")
);

// Render Groups Widgets
let groups = document.querySelectorAll("[data-pcoplus-widget=groups]");
for (const groupWidget of groups) {
	if (groupWidget.getAttribute("data-rendered") !== "true") {
		groupWidget.setAttribute("data-rendered", "true");

		ReactDOM.render(
			<React.StrictMode>
				<StyleReset>
					<Suspense fallback={<div>Loading...</div>}>
						<Groups attributes={{ ...groupWidget.dataset }} />
					</Suspense>
				</StyleReset>
			</React.StrictMode>,
			groupWidget
		);
	}
}

// Render Event List Widgets
let eventlists = document.querySelectorAll("[data-pcoplus-widget=eventlist]");
for (const eventlistWidget of eventlists) {
	if (eventlistWidget.getAttribute("data-rendered") !== "true") {
		eventlistWidget.setAttribute("data-rendered", "true");

		ReactDOM.render(
			<React.StrictMode>
				<StyleReset>
					<Suspense fallback={<div>Loading...</div>}>
						<EventsList attributes={{ ...eventlistWidget.dataset }} />
					</Suspense>
				</StyleReset>
			</React.StrictMode>,
			eventlistWidget
		);
	}
}

// Render Calendar Widgets
let calendars = document.querySelectorAll("[data-pcoplus-widget=calendar]");
for (const calendarWidget of calendars) {
	if (calendarWidget.getAttribute("data-rendered") !== "true") {
		calendarWidget.setAttribute("data-rendered", "true");

		ReactDOM.render(
			<React.StrictMode>
				<StyleReset>
					<Suspense fallback={<div>Loading...</div>}>
						<Calendar attributes={{ ...calendarWidget.dataset }} />
					</Suspense>
				</StyleReset>
			</React.StrictMode>,
			calendarWidget
		);
	}
}

// Render Upcoming Services Widgets
let upcomingServices = document.querySelectorAll(
	"[data-pcoplus-widget=upcomingServices]"
);
for (const upcomingServiceWidget of upcomingServices) {
	if (upcomingServiceWidget.getAttribute("data-rendered") !== "true") {
		upcomingServiceWidget.setAttribute("data-rendered", "true");
		ReactDOM.render(
			<React.StrictMode>
				<StyleReset>
					<Suspense fallback={<div>Loading...</div>}>
						<UpcomingServices
							attributes={{ ...upcomingServiceWidget.dataset }}
						/>
					</Suspense>
				</StyleReset>
			</React.StrictMode>,
			upcomingServiceWidget
		);
	}
}
